<template>
<div class="row tgju-widgets-row profile-today">
    <slot v-if="widgetLoad">
        <div class="text-center p-2 w100-w">
            <div class="widget-loading"></div>
        </div>
    </slot>
    <slot v-else>

        <div class="market-profile-inner">
            <div class="profile-inner-filter-row">
                <div class="filter-row-name">
                    <i class="fa fa-clock-o" aria-hidden="true"></i> {{ market_today.info.time }} 
                </div>
                <div class="filter-row-btns">
                    <i class="uil uil uil-search" @click="showSearch = !showSearch,showFilter = false"></i>
                    <i class="uil uil uil-filter" @click="showFilter = !showFilter,showSearch = false"></i>
                </div>
            </div>
            <div v-if="showFilter || showSearch" class="market-profile-filter-box">
                <div v-if="showFilter" class="filter-box-select profile-mobile-view">
                    <v-select v-if="selectList.length" label="بازه زمانی" class="profile-mobile-view-select mobile-view-select-modal " :items="selectList" item-text="text"  item-value="value" @change="onChange($event)" outlined></v-select>
                </div>
                <v-text-field v-if="showSearch" v-model="searchVal" @input="search()" id="datatables-search-box" class="mobile-app-input" style="margin-bottom: 7px;" label="جستجو ..."></v-text-field>
            </div>

            <ul class="m-pointer">
                <li class="li-head">
                    <div class="profile-inner-table-row">
                        <div class="profile-inner-table-name">زمان</div>
                        <div class="profile-inner-table-value">قیمت</div>
                        <div class="profile-inner-table-actions actions-min-w"></div>
                    </div>
                </li>

                <li v-for="(item, index) in market_today.today_table" :key="index" :class="activeLi == index ? 'active-current' : ''">
                    <div class="profile-inner-table-row" @click="setActive(index)">
                        <div class="profile-inner-table-name">{{ item.time }}</div>
                        <div class="profile-inner-table-value">{{ formatPrice(item.value) }}</div>
                        <div class="profile-inner-table-actions actions-min-w"><i class="uil uil-angle-down"></i></div>
                    </div>
                    <div class="profile-inner-table-box">
                        <div class="profile-inner-table-item">
                            <div class="profile-inner-table-row">
                                <div class="profile-inner-table-name">تغییر نرخ قبلی</div>
                                <div class="profile-inner-table-value" :class="`${ item.dt }`">{{ formatPrice(item.d) }}</div>
                            </div>
                        </div>                        
                        <div class="profile-inner-table-item">
                            <div class="profile-inner-table-row">
                                <div class="profile-inner-table-name">درصد تغییر نرخ قبلی</div>
                                <div class="profile-inner-table-value" v-if="item.dp != '-' && item.dp != ''" :class="`${ item.dt }`">{{ formatPrice(item.dp) }}%</div>
                                <div class="profile-inner-table-value" v-else>-</div>
                            </div>
                        </div>
                        <div class="profile-inner-table-item" >
                            <div class="profile-inner-table-row">
                                <div class="profile-inner-table-name">تغییر روز گذشته</div>
                                <div class="profile-inner-table-value" v-if="item.tolerance_yesterday.dp != '-' && item.tolerance_yesterday.dp != ''" :class="`${ item.tolerance_yesterday.dt }`" >{{ formatPrice(item.tolerance_yesterday.d) }}</div>
                                <div class="profile-inner-table-value" v-else>-</div>
                            </div>
                        </div>
                        <div class="profile-inner-table-item" >
                            <div class="profile-inner-table-row">
                                <div class="profile-inner-table-name">درصد تغییر روز گذشته</div>
                                <div class="profile-inner-table-value" v-if="item.tolerance_yesterday.dp != '-' && item.tolerance_yesterday.dp != ''" :class="`${ item.tolerance_yesterday.dt }`" >{{ formatPrice(item.tolerance_yesterday.dp) }}%</div>
                                <div class="profile-inner-table-value" v-else>-</div>
                            </div>
                        </div>
                        
                        <div class="profile-inner-table-item" >
                            <div class="profile-inner-table-row">
                                <div class="profile-inner-table-name"> تغییر نرخ بازگشایی</div>
                                <div class="profile-inner-table-value" v-if="item.tolerance_open.dp != '-' && item.tolerance_open.dp != ''" :class="`${ item.tolerance_open.dt }`" >{{ formatPrice(item.tolerance_open.d) }}</div>
                                <div class="profile-inner-table-value" v-else>-</div>
                            </div>
                        </div>

                        <div class="profile-inner-table-item" >
                            <div class="profile-inner-table-row">
                                <div class="profile-inner-table-name">درصد تغییر نرخ بازگشایی</div>
                                <div class="profile-inner-table-value" v-if="item.tolerance_open.dp != '-' && item.tolerance_open.dp != ''" :class="`${ item.tolerance_open.dt }`" >{{ formatPrice(item.tolerance_open.dp) }}%</div>
                                <div class="profile-inner-table-value" v-else>-</div>
                            </div>
                        </div>
                        

                        <div class="profile-inner-table-item" >
                            <div class="profile-inner-table-row">
                                <div class="profile-inner-table-name">مقدار تغییر هفته</div>
                                <div class="profile-inner-table-value" v-if="item.tolerance_week_max.dp != '-' && item.tolerance_week_max.dp != ''" :class="`${ item.tolerance_week_max.dt }`">{{ formatPrice(item.tolerance_week_max.d) }}</div>
                                <div class="profile-inner-table-value" v-else>-</div>
                            </div>
                        </div>

                        <div class="profile-inner-table-item" >
                            <div class="profile-inner-table-row">
                                <div class="profile-inner-table-name">درصد تغییر هفته</div>
                                <div class="profile-inner-table-value" v-if="item.tolerance_week_avg.dp != '-' && item.tolerance_week_avg.dp != ''" :class="`${ item.tolerance_week_avg.dt }`">{{ formatPrice(item.tolerance_week_avg.d) }}%</div>
                                <div class="profile-inner-table-value" v-else>-</div>
                            </div>
                        </div>
                        

                    </div>
                    
                </li>
            </ul>

        </div>


        <!-- <div class="tgju-widgets-block col-md-12 col-lg-12">
            <div class="tgju-widget light has-icon">
                <div v-if="cryptoembed == null" class="tgju-widget-title dot m-0">
                    <h2>{{ market_today.info.title }} در روز جاری</h2>
                    <span class="tgju-widget-up-i">{{ market_today.info.time }} <i class="fa fa-clock-o" aria-hidden="true"></i></span>
                </div>
                <div class="clearfix tgju-widget-forms">
                    <div class="filters-wrapper">
                        <div class="filter-wrapper">
                            <div class="search-box" data-target="profile-tour-step-11">
                                <div class="date-icon"></div>
                                <input type="text" id="datatables-search-box" class="search" placeholder="جستجو ..." value="" name="search" style="min-width: 330px;">
                            </div>
                        </div>
                    </div>
                    <div class="filters-wrapper float-left" data-target="profile-tour-step-12">
                        <div class="filters-wrapper-label" style="padding-top: 3px;">تغییر نسبت به : </div>
                        <div class="filter-wrapper">
                            <select class="filter-range" @change="onChange($event)">
                                <option value="0">بازه زمانی</option>
                                <option value="week" selected>هفته</option>
                                <option value="month">ماه</option>
                                <option value="3_month">سه ماه</option>
                                <option value="6_month">شش ماه</option>
                                <option value="1_year">یک سال</option>
                            </select>
                        </div>
                    </div>
                </div>

                <div id="stocks-table-data" class="mobile-profile-data-table tables-data table-mobile-grid" data-target="today-tour-list">
                    <table id="datatables-container-1" class="table widgets-dataTable table-hover text-center today-table table-mobile-title">
                        <thead class="text-center">
                            <tr>
                                <th data-target="profile-tour-step-13">قیمت</th>
                                <th data-target="profile-tour-step-14">زمان</th>

                                <th class="text-center bt-0" data-target="profile-tour-step-15">
                                    <span class="clearfix color-gray mt-0">مقدار تغییر نسبت به</span>
                                    <div class="clearfix" style="margin-top: 5px;">نرخ قبلی</div>
                                </th>
                                <th class="text-center bt-0" data-target="profile-tour-step-16">
                                    <span class="clearfix color-gray mt-0">درصد تغییر نسبت به</span>
                                    <div class="clearfix" style="margin-top: 5px;">نرخ قبلی</div>
                                </th>
                                
                                <th class="text-center bt-0" data-target="profile-tour-step-17">
                                    <span class="clearfix color-gray mt-0">مقدار تغییر نسبت به</span>
                                    <div class="clearfix" style="margin-top: 5px;">نرخ روز گذشته</div>
                                </th>
                                <th class="text-center bt-0" data-target="profile-tour-step-18">
                                    <span class="clearfix color-gray mt-0">درصد تغییر نسبت</span>
                                    <div class="clearfix" style="margin-top: 5px;">نرخ روز گذشته</div>
                                </th>
                                <th class="text-center bt-0" data-target="profile-tour-step-19">
                                    <span class="clearfix color-gray mt-0">مقدار تغییر نسبت به</span>
                                    <div class="clearfix" style="margin-top: 5px;">نرخ بازگشایی</div>
                                </th>
                                <th class="text-center bt-0" data-target="profile-tour-step-20">
                                    <span class="clearfix color-gray mt-0">درصد تغییر نسبت</span>
                                    <div class="clearfix" style="margin-top: 5px;">نرخ بازگشایی</div>
                                </th>
                                <th class="text-center bt-0" data-target="profile-tour-step-21">
                                    <span class="clearfix color-gray mt-0">مقدار تغییر نسبت به</span>
                                    <div class="clearfix">بالاترین نرخ <span class="tolerance-range-text">هفته</span></div>
                                </th>
                                <th class="text-center bt-0"  data-target="profile-tour-step-22">
                                    <span class="clearfix color-gray mt-0">مقدار تغییر نسبت به</span>
                                    <div class="clearfix">میانگین نرخ <span class="tolerance-range-text">هفته</span></div>
                                </th>
                            </tr>
                        </thead>
                        <tbody id="table-list" class="text-center">
                            <tr v-for="(item, index) in market_today.today_table" :key="index">
                                <td>{{ formatPrice(item.value) }}</td>

                                <td>{{ item.time }}</td>

                                <td><span :class="`${ item.dt }`" dir="ltr">{{ formatPrice(item.d) }}</span></td>

                                <td v-if="item.dp != '-' && item.dp != ''"><span :class="`${ item.dt }`" dir="ltr">{{ formatPrice(item.dp) }}%</span></td><td v-else>-</td>

                                <td v-if="item.tolerance_yesterday.dp != '-' && item.tolerance_yesterday.dp != ''"><span :class="`${ item.tolerance_yesterday.dt }`" dir="ltr">{{ formatPrice(item.tolerance_yesterday.d) }}</span></td><td v-else>-</td>

                                <td v-if="item.tolerance_yesterday.dp != '-' && item.tolerance_yesterday.dp != ''"><span :class="`${ item.tolerance_yesterday.dt }`" dir="ltr">{{ formatPrice(item.tolerance_yesterday.dp) }}%</span></td><td v-else>-</td>

                                    <td v-if="item.tolerance_open.dp != '-' && item.tolerance_open.dp != ''"><span :class="`${ item.tolerance_open.dt }`" dir="ltr">{{ formatPrice(item.tolerance_open.d) }}</span></td><td v-else>-</td>

                                <td v-if="item.tolerance_open.dp != '-' && item.tolerance_open.dp != ''"><span :class="`${ item.tolerance_open.dt }`" dir="ltr">{{ formatPrice(item.tolerance_open.dp) }}%</span></td><td v-else>-</td>

                                <td v-if="item.tolerance_week_max.dp != '-' && item.tolerance_week_max.dp != ''"><span :class="`${ item.tolerance_week_max.dt }`" dir="ltr">{{ formatPrice(item.tolerance_week_max.d) }}</span></td><td v-else>-</td>

                                <td v-if="item.tolerance_week_avg.dp != '-' && item.tolerance_week_avg.dp != ''"><span :class="`${ item.tolerance_week_avg.dt }`" dir="ltr">{{ formatPrice(item.tolerance_week_avg.d) }}</span></td><td v-else>-</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div> -->
    </slot>
</div>
</template>

<script>
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import $ from "jquery";
export default {
    name: "MarketTodayComponent",
    data() {
        return {
            widgetLoad: true,
            initDatatable: false,
            market_today: [],
            cryptoembed: null,
            datatable: null,
            selectedList :0,
            showSearch:false,
            showFilter:false,
            activeLi:null,
            searchVal:'',
            selectList: [
                { text :'هفته',value : 'week'},
                { text :'ماه',value : 'month'},
                { text :'سه ماه',value : '3_month'},
                { text :'شش ماه',value : '6_month'},
                { text :'یک سال',value : '1_year'}
            ],
        }
    },
    updated() {
        if (!this.initDatatable) {
            this.initDatatables();
            this.initDatatable = true;
        }
    },
    mounted() {
        this.getProfileToday();

    },
    methods: {
        search(){
            var value = this.searchVal.toLowerCase();
            $(".market-profile-inner li").each(function() {
                var x = $(this).text().toLowerCase().indexOf(value) > -1;
                $(this).toggle(x)
            });
        },
        setActive(index){
            if(index == this.activeLi){
                this.activeLi = null;
            }else{
                this.activeLi = index;
            }

        },
        today_datatables_params(data) {
            var order_col = data.order.length ? data.order[0]['column'] : null;

            if ( order_col !== null ) {
                switch ( order_col ) {
                    case 0:
                        order_col = 'value';
                        break;
                    case 1:
                        order_col = 'datetime';
                        break;
                    case 2:
                        order_col = 'd';
                        break;
                    case 3:
                        order_col = 'dp';
                        break;
                    case 4:
                        order_col = 'tolerance_open.d';
                        break;
                    case 5:
                        order_col = 'tolerance_open.dp';
                        break;
                    case 6:
                        order_col = 'tolerance_week_max.d';
                        break;
                    case 7:
                        order_col = 'tolerance_week_avg.d';
                        break;
                }
            }
            return $.extend({}, data, {
                search: $('.filters-wrapper input.search').val().trim(),
                today_table_tolerance_open: 1,
                today_table_tolerance_yesterday: 1,
                today_table_tolerance_range: $('.filter-range').val(),
                order_col: order_col,
                order_dir: data.order.length ? data.order[0]['dir'] : null,
            });
        },
        getProfileToday() {
            this.$helpers
                .makeRequest("GET", `/market/profile-today/${this.$route.params.name}`)
                .then((api_response) => {
                    if (api_response.status == 200) {
                        this.market_today = api_response.data.response.detail.response;
                        this.$parent.title = this.market_today.info.title + ' - ' + this.$route.meta.title;
                        this.widgetLoad = false;
                    }
                });
        },
        initDatatables() {
            this.datatable = $('#datatables-container-1').DataTable({
                        language: {
                            "sProcessing": "درحال پردازش...",
                            "sLengthMenu": "نمایش محتویات : _MENU_",
                            "sZeroRecords": "موردی یافت نشد",
                            "sInfo": "نمایش _START_ تا _END_ از مجموع _TOTAL_ مورد",
                            "sInfoEmpty": "خالی",
                            "sInfoFiltered": "(فیلتر شده از مجموع _MAX_ مورد)",
                            "sInfoPostFix": "",
                            "sSearch": "فیلتر : ",
                            "sUrl": "",
                            "oPaginate": {
                                "sFirst": "ابتدا",
                                "sPrevious": "قبلی",
                                "sNext": "بعدی",
                                "sLast": "انتها"
                            }
                        },
                        aaSorting: [],
                        ordering: true,
                        paging: true,
                        info: true,
                        columnDefs: [{
                            "targets": 'no-sort',
                            "orderable": false,
                        }],
                        pageLength: 30,
                        // TODO: "<'row'<'col-md-9'l T C><'col-md-3'f>r>t<'row'<'col-md-12'p i>>R",
                        sDom: "<''<'col-md-9'l T C><'col-md-3'f>r>t<''<'col-md-12'p i>>R",
                        direction: 'rtl',
                        processing: true,
                        serverSide: true,
                        ajax: {
                                "url": `https://api.tgju.org/v1/market/indicator/today-table-data/${this.$route.params.name}?lang=fa&identification=smart`,
                                "dataType": "json",
                                "data": this.today_datatables_params
                        }
            });

            var test_ajax = this.datatable;
            $('#datatables-search-box').keyup(function() {
                test_ajax.search($(this).val()).draw();
            });
        },
        // این متد جهت نمایش بهتر نرخ ها و قیمت ها می باشد // جدا کننده قیمت با کاما
        formatPrice(value) {
            if(isNaN(value) || value == '') {
                return '-';
            }

            value = parseFloat(value);
            return parseFloat(value.toFixed(2)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        },
        onChange(event) {
            if ( event == 0 ) {
                return;
            }            
            $('.tolerance-range-text').text(this.selectList.find((item) => item.value === event).text);
            this.datatable.ajax.reload();
        }
    },
}
</script>