<template>
<div class="row tgju-widgets-row dashboard-widgets-technical-list dash-full-height">
    <div class="col-12" style="padding: 0 !important;">
        <MarketHeader :data="$route.params.name"></MarketHeader>
        <!-- <div class="market-main-sub-tabs w-scroll-style">
            <div v-for="(item, index) in marketNavPage" :key="index" v-if="!item.hide" :class="'market-main-sub-tab-item ' + (item.name == tabName ? 'active' : '') ">
                <router-link :to="{name: item.name, params: { name: $route.params.name }, }">
                    <i :class="item.icon"></i>
                    <span>{{ item.title }}</span>
                </router-link>
            </div>
        </div> -->
    </div>
    <MarketToday></MarketToday>
</div>
</template>

<style>
    #datatables-search-box,
    .filter-range {
        height: 40px !important;
        border: 1px solid #ccc !important;
        box-shadow: unset !important;
    }
    html body .widgets .tgju-widget .tables-data .dataTables_wrapper tbody tr td {
        border-bottom: unset !important;
        border-top: none !important;
    }
    html .widgets .tgju-widget .table > tbody > tr > td {
        height: auto;
    }
    body.profile .profile-indicator .table-mobile-grid table.table tbody tr {
        border-bottom: none;
    }
    @media (max-width: 1220px) {
        .tgju-widget .table-mobile-title tbody tr td:first-child::before {
            content: 'قیمت' !important;
        }

        .tgju-widget .table-mobile-grid tbody tr td:nth-child(2)::before {
            content: 'سال : ' !important;
        }

        .tgju-widget .table-mobile-grid tbody tr td:nth-child(3)::before {
            content: 'تغییر نرخ قبلی' !important;
        }

        .tgju-widget .table-mobile-grid tbody tr td:nth-child(4)::before {
            content: '% تغییر نرخ قبلی' !important;
        }

        .tgju-widget .table-mobile-grid tbody tr td:nth-child(5)::before {
            content: 'تغییر روز گذشته' !important;
        }

        .tgju-widget .table-mobile-grid tbody tr td:nth-child(6)::before {
            content: '% تغییر روز گذشته' !important;
        }

        .tgju-widget .table-mobile-grid tbody tr td:nth-child(7)::before {
            content: 'تغییر نرخ بازگشایی' !important;
        }

        .tgju-widget .table-mobile-grid tbody tr td:nth-child(8)::before {
            content: '% تغییر نرخ بازگشایی' !important;
        }

        .tgju-widget .table-mobile-grid tbody tr td:nth-child(9)::before {
            content: 'بالاترین نرخ هفته' !important;
        }

        .tgju-widget .table-mobile-grid tbody tr td:nth-child(10)::before {
            content: 'میانگین نرخ هفته' !important;
        }
    }
</style>
<script>
// این ویو برای ماژول  بازار  پروفایل شاخص ، بخش اولیه مورد استفاده قرار میگیرد
import MarketToday from "@/components/Mobile/Market/Profile/Today";
import marketNavPage from "@/assets/static_market_nav_page";
import MarketHeader from '@/components/Mobile/Market/Profile/Header';

export default {
    name: "marketToday",
    components: {
        MarketToday,
        MarketHeader,
    },
    data: function () {
        return {
            title: "--",
            symbol: "--",
            marketNavPage,
            tabName:'MarketToday',
        };
    },
    mounted() {
    },
};
</script>
